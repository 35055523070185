import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ForgotPasswordComponent } from './components/login/forgot-password/forgot-password.component';
import { NewAccountComponent } from './components/login/new-account/new-account.component';
import { PromoActivityReportComponent } from './components/activity-reports/promo-activity-report/promo-activity-report.component';
import { MandiCampaignReportComponent } from './components/activity-reports/mandi-campaign-report/mandi-campaign-report.component';
import { DemoL3ReportComponent } from './components/activity-reports/demo-l3-report/demo-l3-report.component';
import { DataSetMasterComponent } from './components/masters/data-set-master/data-set-master.component';
import { EmployeeMasterComponent } from './components/masters/employee-master/employee-master.component';
import { CatalogMasterComponent } from './components/masters/catalog-master/catalog-master.component';
import { VillageMasterComponent } from './components/masters/village-master/village-master.component';
import { VerificationEmailComponent } from './components/login/forgot-password/verification-email/verification-email.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path:'verification-email',component:VerificationEmailComponent},
  { path: 'new-account', component: NewAccountComponent },
  { path: 'promo-activity-report', component: PromoActivityReportComponent, canActivate: [AuthGuardService] },
  { path: 'mandi-campaign-report', component: MandiCampaignReportComponent, canActivate: [AuthGuardService] },
  { path: 'demo-l3-report', component: DemoL3ReportComponent, canActivate: [AuthGuardService] },
  { path: 'data-set-master', component: DataSetMasterComponent, canActivate: [AuthGuardService] },
  { path: 'employee-master', component: EmployeeMasterComponent, canActivate: [AuthGuardService] },
  { path: 'catalog-master', component: CatalogMasterComponent, canActivate: [AuthGuardService] },
  { path: 'village-master', component: VillageMasterComponent, canActivate: [AuthGuardService] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
