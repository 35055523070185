import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { MatDrawer } from '@angular/material';
import { Router } from '@angular/router';

var misc: any = {
  sidebar_mini_active: true
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}
//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/promo-activity-report",
    title: "Activity Reports",
    type: "sub",
    icontype: "ni-shop color-icl",
    isCollapsed: true,
    children: [
      { path: "/promo-activity-report", title: "Promo Activity Report", type: "link" },
      { path: "/mandi-campaign-report", title: "Mandi Campaign Report", type: "link" },
      { path: "/demo-l3-report", title: "Demo L3 Report", type: "link" }
    ]
  },
  {
    path: "/data-set-master",
    title: "Masters",
    type: "sub",
    icontype: "ni-ungroup color-icl",
    collapse: "examples",
    isCollapsed: true,
    children: [
      // { path: "/data-set-master", title: "Data Set Master", type: "link" },
      { path: "/employee-master", title: "Employee Master", type: "link" },
      { path: "/catalog-master", title: "Catalog Master", type: "link" },
      { path: "/village-master", title: "Village Master", type: "link" }
    ]
  }
];
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;
  constructor(private router: Router, private loginService: LoginService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe(event => {
      this.isCollapsed = true;
    });
  }

  isLogin() {
    return this.loginService.isUserLogin();
  }
  checkLogOut(path: string) {
    if (path == "/login")
      this.logOut();
  }
  logOut() {
    this.loginService.logOut();
  }

  onMouseEnterSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  }
  minimizeSidebar() {
    const sidenavToggler = document.getElementsByClassName(
      "sidenav-toggler"
    )[0];
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("g-sidenav-pinned")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("g-sidenav-pinned");
      body.classList.add("g-sidenav-hidden");
      sidenavToggler.classList.remove("active");
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add("g-sidenav-pinned");
      body.classList.remove("g-sidenav-hidden");
      sidenavToggler.classList.add("active");
      misc.sidebar_mini_active = true;
    }
  }
}
