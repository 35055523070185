import { Component, OnInit, Input, Output, EventEmitter, Inject, ViewChild, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatAutocomplete } from '@angular/material';

import { HttpService } from 'src/app/services/http.service';
@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopupComponent implements OnInit, ControlValueAccessor {
  @Output() saveChanges = new EventEmitter();
  public fullData: FormGroup = new FormGroup({});
  private arrFormBulder = {};
  public popupData;
  public showPassword: boolean = false;
  public isUser: boolean = false;

  constructor(public fb: FormBuilder, private dialogRef: MatDialogRef<PopupComponent>, private httpService: HttpService, @Inject(MAT_DIALOG_DATA) data) {
    this.popupData = data;
    this.setControlFields(data);
  }


  writeValue(obj: any): void {
    throw new Error("Method not implemented.");
  }
  registerOnChange(fn: any): void {
    throw new Error("Method not implemented.");
  }
  registerOnTouched(fn: any): void {
    throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {
  }
  setControlFields(data) {
    if (data && data.fields.length > 0) {
      this.arrFormBulder = [];
      data.fields.forEach((field: any) => {
        if (field.type != 6) {
          var name = field.fieldName;
          this.arrFormBulder[name] = ["", field.isRequired ? Validators.required : ""];
          if(this.popupData.button!='Add'){
            this.arrFormBulder[name] =[field.value,field.isRequired ? Validators.required : null];
          }
        }
      })
      this.fullData = this.fb.group(this.arrFormBulder);
      this.onSelectChange('State',this.fullData.controls['State'].value);
      this.onSelectChange('District',this.fullData.controls['District'].value);
      this.onSelectChange('Location',this.fullData.controls['Location']? this.fullData.controls['Location'].value:"");
    }
  }
  onSelectChange(fieldName, value) {
    if (fieldName == 'State' && value!="") {
      const state = `${value}`.split(',')[0];
      let districtField = this.popupData.fields.find( field => field.fieldName == "District");
      districtField.valuesDropDown = this.buildDistrictDropDown(state,districtField.generalValues);
      this.popupData.fields[this.popupData.fields.findIndex(field=>field.fieldName=="District")]=districtField;
      this.fullData.controls["State"].patchValue(value);
    }
    if(fieldName =='District' && value!=""){
      const district = `${value}`;
      let locationsField = this.popupData.fields.find( field => field.fieldName == "Location");
      if(locationsField){
        locationsField.valuesDropDown = this.buildLocationDropDown(district,locationsField.generalValues);
        this.popupData.fields[this.popupData.fields.findIndex(field=>field.fieldName=="Location")]=locationsField;
      }
     
      this.fullData.controls["District"].patchValue(value);
    }
    if(fieldName == "Location" && value!="" ) this.fullData.controls["Location"].patchValue(value);
  }
  buildLocationDropDown(district,generalLocations) {
    const currentLocations = `${generalLocations.filter(location => `${location}`.split('-')[1] == `${district}`.trim())[0]}`;
    const locationsDropDown = currentLocations && currentLocations!="undefined" ?  currentLocations.split('-')[2].split(','):[];
    return locationsDropDown;
  }
  buildDistrictDropDown(state,generalDistrict){
    const currentDistricts = `${generalDistrict.filter(district => `${district}`.split('-')[0] == `${state}`.trim())[0]}`;
    const districtDropDown = currentDistricts && currentDistricts!="undefined" ?  currentDistricts.split('-')[1].split(','):[];
    return districtDropDown;
  }

  getLocationList(districtLocations, district){
    let locationsDropDown;
    districtLocations.forEach( districtLocation => {
      let currentDistrict = districtLocation.split('-')[0].trim();
      if(currentDistrict == district) locationsDropDown = districtLocation.split('-')[1].trim().split(',');; 
    })
    locationsDropDown = locationsDropDown.map( location => `${location}`.trim())
    return locationsDropDown;
  }
  saveData() {
    switch (this.popupData.type) {
      case "user":
        this.fullData.value['Email-id'] = this.fullData.value['Email-id'].toLowerCase();
        this.dialogRef.close(this.fullData.value);
        break;
      case "catalog":
        this.dialogRef.close(this.buildCatalogObj());
        break;
      case "Employee":
        this.dialogRef.close({id:this.popupData.id,fields:this.fullData.value});
        break;
      case "State District":
        this.dialogRef.close(this.buildStateDistrictObj());
        break;
      default:
        break;
    }

  }
  buildStateDistrictObj(){
    return {id:this.popupData.id,fields:{state: this.fullData.value.State.trim().toLowerCase(), district: this.fullData.value.District.trim().toLowerCase(),  location: this.fullData.value.Location.trim().toLowerCase().split(',')}}
  }
  buildCatalogObj() {
    return { state: this.fullData.value.State.trim().toLowerCase(), district: this.fullData.value.District.trim().toLowerCase(),  location: this.fullData.value.Location.trim().toLowerCase().split(',') }
  }
  close() {
    this.dialogRef.close();
  }
}
