import { BrowserModule } from '@angular/platform-browser';
import { NgModule, forwardRef } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/shared-component/header/header.component';
import { MenuComponent } from './components/shared-component/menu/menu.component';
import { PopupComponent } from './components/shared-component/popup/popup.component';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SearchTableComponent } from './components/shared-component/search-table/search-table.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog'
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material';
import { MatSortModule } from '@angular/material/sort';
import { MatDividerModule } from '@angular/material/divider';

import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service

import { CollapseModule } from "ngx-bootstrap/collapse";

// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
// import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DatePipe } from '@angular/common';
import { MatTreeModule } from '@angular/material/tree';
import { NgxTagsInputModule } from 'ngx-tags-input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TreeViewComponent } from './components/shared-component/tree-view/tree-view.component';
import { ForgotPasswordComponent } from './components/login/forgot-password/forgot-password.component';
import { NewAccountComponent } from './components/login/new-account/new-account.component';
import { PromoActivityReportComponent } from './components/activity-reports/promo-activity-report/promo-activity-report.component';
import { MandiCampaignReportComponent } from './components/activity-reports/mandi-campaign-report/mandi-campaign-report.component';
import { DemoL3ReportComponent } from './components/activity-reports/demo-l3-report/demo-l3-report.component';
import { DataSetMasterComponent } from './components/masters/data-set-master/data-set-master.component';
import { EmployeeMasterComponent } from './components/masters/employee-master/employee-master.component';
import { CatalogMasterComponent } from './components/masters/catalog-master/catalog-master.component';
import { VillageMasterComponent } from './components/masters/village-master/village-master.component';
import { MasterTableComponent } from './components/shared-component/master-table/master-table.component';
import { VerificationEmailComponent } from './components/login/forgot-password/verification-email/verification-email.component';
import { MatProgressSpinnerModule } from '@angular/material';

// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   suppressScrollX: true
// };
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    MenuComponent,
    PopupComponent,
    SearchTableComponent,
    TreeViewComponent,
    ForgotPasswordComponent,
    NewAccountComponent,
    PromoActivityReportComponent,
    MandiCampaignReportComponent,
    DemoL3ReportComponent,
    DataSetMasterComponent,
    EmployeeMasterComponent,
    CatalogMasterComponent,
    VillageMasterComponent,
    MasterTableComponent,
    VerificationEmailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    NgxTagsInputModule,
    MatIconModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatSidenavModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    // PerfectScrollbarModule,
    CollapseModule.forRoot(),
    MatTreeModule,
    MatCheckboxModule,
    MatSortModule,
    MatDividerModule
  ],
  providers: [
    DatePipe,
    //   {
    //   provide:PERFECT_SCROLLBAR_CONFIG,
    //   useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    //   multi:true,
    //   useExisting:forwardRef(()=>PopupComponent)

    // },
    // {
    //   // provide: PERFECT_SCROLLBAR_CONFIG,
    //   // useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    // },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    BnNgIdleService],
  bootstrap: [AppComponent],
  entryComponents: [
    PopupComponent],
  exports: [MenuComponent]
})
export class AppModule { }
