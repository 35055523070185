import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})
export class TreeViewComponent implements OnInit {
  @Output() applyFilter = new EventEmitter();
  public treeViewFieldsList =[];
  public treeViewData:FormGroup = new FormGroup({});
  private arrFormBulder: {};
  constructor(private fb:FormBuilder) {
   }

   ngOnInit() {
    this.initializeTreeViewFieldsList();
    this.setControlFields();
  }
  initializeTreeViewFieldsList(){
    // this.treeViewFieldsList = this.testResultService.getFilterTreeObj();
  }
  setControlFields(){
    if(this.treeViewFieldsList){
      this.arrFormBulder = {};
      this.treeViewFieldsList.forEach((field,index)=>{
        this.arrFormBulder[field.mainField] = [field.options.length==1 && index==0?true:field.selected || false];
        field.options.forEach((option,innerIndex) => {
          this.arrFormBulder[field.mainField+option] = [index==0&&innerIndex==0? true:field.selected || false];
        });
      })
      this.treeViewData = this.fb.group(this.arrFormBulder)
    }
  }
  checkUncheckAllChildren(fieldName){
    let optionsByMainField = this.treeViewFieldsList.find(fields=>fields.mainField==fieldName);
    optionsByMainField.options.forEach(option => {
      this.treeViewData.controls[optionsByMainField.mainField+option].setValue(this.treeViewData.controls[optionsByMainField.mainField].value);
    });
  }
  checkUncheckParent(parentFieldName){
    let index = 0;
    let optionsByMainField = this.treeViewFieldsList.find(fields=>fields.mainField==parentFieldName);
    optionsByMainField.options.forEach(option => {
      if(this.treeViewData.controls[optionsByMainField.mainField+option].value == true){
        index++;
      }
    });
    if(index==optionsByMainField.options.length){
      this.treeViewData.controls[optionsByMainField.mainField].setValue(true);
    }
    else{
      this.treeViewData.controls[optionsByMainField.mainField].setValue(false);
    }
  }
  applyFilterFunc(){
    this.applyFilter.emit(this.treeViewData)
  }
}
